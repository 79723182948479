// Spiner Reviews Carousel

$(document).ready(function() {

    if ($(window).width() > 1200) {

            $("div.Timer").addClass("is-running");

            function triggerNext() {
            $('.reviews__list').trigger('next.owl.carousel');
            }

            var interval = setInterval(triggerNext, 3990);

            $('.owl-next, .owl-prev').click(function() {
            clearInterval(interval);
            resetAnimations();
            interval = setInterval(triggerNext, 3990);
            });

            function resetAnimations() {
            $('.Timer').removeClass('is-running');
            $('.Timer-mask').css('display', 'none');
            setTimeout(function() {
                $('.Timer').addClass('is-running');
                $('.Timer-mask').css('display', '');
            }, 0);
            }

    }


  });
