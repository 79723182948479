const btnMobile = document.querySelector(".main-navigation__btn-mobile");
const offCanvas =  document.querySelector(".mobile-menu");
const linkMobile =  document.querySelectorAll(".mobile-menu .close");
const overlay = document.querySelector(".overlay-body");
const html = document.documentElement;

btnMobile.addEventListener("click", e => {
    offCanvas.classList.toggle("active");
    overlay.classList.toggle("active");
    html.style.overflow = "hidden";
});


linkMobile.forEach(link => {
    link.addEventListener("click", e => {
        offCanvas.classList.remove("active")
        overlay.classList.remove("active");
        html.style.overflow = "";
    })
})
