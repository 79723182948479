$(document).ready(function () {

    $('.security__list').owlCarousel({

        loop:true,
        margin:20,
        nav: false,
        autoplay: false,
        // autoHeight: true, // Dodaj tę opcję
        // center: true,

        responsive:{
           0:{
                items:1.3,
                mouseDrag: false,
                touchDrag: true,

            },
            768:{
                items:2,
                mouseDrag: false,
                touchDrag: true,


            },
            1200:{
                items:3,
                mouseDrag: false,
                touchDrag: false,
                center: true,
                // autoplay: true,
            }
        },


        autoplayTimeout: 2000,

    });




    $('.reviews__list').owlCarousel({

        margin:20,
        // center: true,
        loop:true,

        mouseDrag: false,

        responsive:{
           0:{
                items: 1.3,
                // autoWidth: true,
                touchDrag: true,
                loop:true,
                autoplay: false,

            },
            768:{
                items:2,
                touchDrag: true,
                loop:true,
                autoplay: false,

            },
            979:{
                items:3,
                touchDrag: true,
                loop:true,
                autoplay: false,


            },
            1200:{
                items:3,
                touchDrag: false,
                loop:true,
                autoplay: false,
            },
            1350:{
                items:4,
                touchDrag: false,
                loop:true,
                autoplay: false,
            }
        },


    });

    $('.owl-prev').click(function() {
        $('.reviews__list').trigger('prev.owl.carousel');
      });

      $('.owl-next').click(function() {
        $('.reviews__list').trigger('next.owl.carousel');
      });



      $('.benefits__list').owlCarousel({

        loop:true,
        margin:20,
        nav: false,

        // autoHeight: true, // Dodaj tę opcję
        // center: true,

        responsive:{
           0:{
                items:1.3,
                mouseDrag: false,
                touchDrag: true,
                autoplay: false,

            },
            768:{
                items:3,
                mouseDrag: false,
                touchDrag: true,
                autoplay: false,


            },
            1300:{
                items:5,
                mouseDrag: true,
                touchDrag: false,
                autoplay: false,
                // center: true,

            }
        },


        autoplayTimeout: 2500,

    });


    $('.after-work__list').owlCarousel({

        margin:20,

        loop:true,

        mouseDrag: false,
        // autoHeight: true,


        responsive:{
           0:{
                items: 1.3,
                // autoWidth: true,
                touchDrag: true,
                loop:true,
                autoplay: false,


            },
            768:{
                items:2,
                touchDrag: true,
                loop:true,
                autoplay: false,
                center: true,

            },
            979:{
                items:2,
                touchDrag: true,
                loop:true,
                autoplay: false,
                center: true,


            },
            1200:{
                items:2,
                touchDrag: false,
                loop:true,
                autoplay: false,
                center: true,
            },
            1350:{
                items:5,
                touchDrag: false,
                loop:true,
                autoplay: false,
                autoWidth:true,
                center: true,
            }
        },


    });

    $('.owl-prev').click(function() {
        $('.after-work__list').trigger('prev.owl.carousel');
      });

      $('.owl-next').click(function() {
        $('.after-work__list').trigger('next.owl.carousel');
      });






});


