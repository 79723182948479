window.addEventListener("load", function () {
    const btnsMore = document.querySelectorAll(".analytics__box-btn");


    btnsMore.forEach(btn => {
        btn.addEventListener("click", function () {
            const boxNumber = btn.getAttribute("data-openbox");
            const targetBox = document.querySelector(`.box-${boxNumber}`);

            if (targetBox) {
                targetBox.classList.toggle('active');
                console.log(`Click ${boxNumber}`);
            }

        });
    });
});
