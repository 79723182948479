window.addEventListener('load', function(){

    let tocHeadings = document.querySelectorAll("#faq-service__content h3");
    let tocList = document.querySelector("#toc__list");

    tocHeadings.forEach((heading) => {

        tocList.innerHTML += `

            <li class="toc__list-item">
                 <a href="#${heading.id}" class="scroll-to-section">${heading.innerText}</a>
            </li>
        `;

    })


})
