$(document).ready(function () {



    $(function() {
        var slider = $("#slider-range-min").slider({
          range: "min",
          value: 5000,
          min: 1000,
          max: 10000,
          slide: function(event, ui) {
            var percentage = (ui.value - 1000) / (10000 - 1000) * 100;
            $("#budget").text(ui.value + " PLN");
            $("#range-line").css("width", percentage + "%");
          }
        });

        $("#amount").on('input', function() {
          var inputVal = $(this).val().replace(/\D/g, '');
          var sliderValue = parseInt(inputVal) || "0";

          if (sliderValue > slider.slider("option", "max")) {
            sliderValue = slider.slider("option", "max");
          }

          $(this).val(sliderValue);
        });

        $("#amount").on('keyup', function(event) {
          if (event.keyCode === 13) {
            var enteredValue = parseInt($(this).val());
            var minValue = slider.slider("option", "min");

            if (enteredValue < minValue) {
              $(this).val("");
              slider.slider("value", minValue);
              $("#budget").text(minValue + " PLN");
              $("#range-line").css("width", "0" + "%");
            } else {
              slider.slider("value", enteredValue);
              $(this).val("");
              $("#budget").text(enteredValue + " PLN");
              var percentage2 = (enteredValue - 1000) / (10000 - 1000) * 100;
            $("#range-line").css("width", percentage2  + "%");
            }
          }
        });

        $("#budget").text($("#slider-range-min").slider("value") + " PLN");

      });

});
